.LBB {
  .product-variant-button:hover {
    color: #000000E0;
    border-color: #132679;
    background-color: #13267933;
  }
  .product-variant-button.active {
    color: #FFFFFF;
    background-color: #132679;
  }
  .product-variant-button.not-available {
    background-color: #DEDEDE;
  }

  @media screen and (max-width: 800px) {
    .lbb-dashboard-button-text {
      display: none;
    }
  }
}
