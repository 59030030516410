.login-bg {
  background-size: cover;
  background-position: center;
}

.login-form {
  width: 350px;
}

.login-input .ant-form-item-label {
  padding: 0 0 3px !important;

  label {
    font-size: 12.5px;
    font-weight: bold;
  }
}

.custom-login-input {
  height: 40px !important;
  border-radius: 4px !important;

  svg path {
    fill: rgba(0, 0, 0, 0.45);
  }

  input {
    height: 29px;
  }
}

.custom-login-captcha-input {
  height: 40px !important;
  border-radius: 4px !important;

  svg path {
    fill: rgba(0, 0, 0, 0.45);
  }

  input {
    height: 23px;
  }
}

.swal2-popup {
  width: 26em;
}

.swal2-styled.swal2-confirm {
  background-color: #222;
  min-width: 100px;
}

.swal2-title {
  padding: 1.2em 1.2em 0;
  line-height: inherit;
  font-weight: 500;
  font-size: 1em;
}

.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #222;
}

.swal2-icon.swal2-success .swal2-success-ring {
  border: 0.25em solid rgb(0 0 0);
}

/* Change auto-fill color*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Custom ANTD Code Start Here */
:where(.css-dev-only-do-not-override-1vtf12y).ant-btn {
  height: 38px;
}

/* Mobile Responsive Code Start Here */
@media (max-width: 767.98px) {
  .login-form {
    max-width: 75%;
  }
}