.subtotal-row {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.cart-list{
  max-height: 300px;
  overflow-y: scroll;
}

.cart-content{
  width: 24rem;

  .row {
    margin: 0;
  }
  [class*="col-"]{
    padding: 0;
  }
}

.point-display{
  font-size: 10px;
}

.cart-button{
  width: 100%;
  text-decoration: none !important;
}

.small-cart-icon-button{
  font-size: 10px !important;
  min-width: 20px !important;
  width: 20px !important;
  height: 20px;
}