.Winna {
  .app-main__inner {
    background-color: #E0DACE;
  }

  .app-header__logo {
    transform: scale(1.5);
  }

  .app-header__heading > .app-header__logo {
    @media screen and (max-width: 600px) {
      transform: scale(4.5);
    }
  }

  .techbase-footer {
    background-color: #E0DACE;
  }

  .registration-form-container {
    input::placeholder,
    button {
      text-transform: uppercase !important;
    }
  }
  
  .row {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .dashbaord-userinfo-status.text-alert {
    color: #FF3D50;
  }

  .dashboard-top-list-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .dashboard-top-list-container::-webkit-scrollbar {
    display: none;
  }

  .product-variant-button {
    color: #222222;
    border: #F5F5F5 2px solid;
    background-color: #F5F5F5;
  }
  .product-variant-button:hover {
    color: #222222;
    border-color: #BCA694;
    background-color: #FFFFFF33;
  }
  .product-variant-button.active {
    border-color: #BCA694;
    background-color: #FFFFFF;
  }
  .product-variant-button.not-available {
    border-color: #DEDEDE;
    background-color: #DEDEDE;
  }

  .reactivate-button {
    color: #FFFFFF;
    border: #FF3D50 1px solid;
    background-color: #FF3D50;
  }
  .reactivate-button:hover {
    color: #FFFFFF;
    border: #FF3D50 1px solid;
    filter: brightness( 0.9 )
  }
  .reload-upgrade-button {
    color: #000000;
    border: #FFEC3D 1px solid;
    background-color: #FFEC3D;
  }
  .reload-upgrade-button:hover {
    color: #000000 !important;
    border: #FFEC3D 1px solid;
    filter: brightness( 1.1 )
  }

  .dashboard-button-disabled {
    cursor: auto !important;
    background-color: #E0DACE !important;

    img {
      opacity: 0.5;
    }
  }
  .dashboard-label-disabled {
    color: gray;
  }

  .dashboard-summary-card {
    display: flex;
    align-items: center;
    height: 100px;
    background-color: #f4f0e6;
    border-radius: 10px;
    padding-left: 8px;
    padding-right: 8px;

    @media screen and (min-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .annoucements-card > div {
    border-radius: 0 0 0 10px;
  }

  @media screen and (min-width: 768px) {
    .annoucements-banner {
      display: none;
    }

    .annoucements-card {
      display: block;
    }

    .dashboard-user-info-card {
      border-radius: 0 0 10px 0;
    }
  }

  @media screen and (max-width: 768px) {
    .annoucements-banner {
      display: block;
    }

    .annoucements-card {
      display: none;
    }

    .winna-top-list {
      width: 100vw;
      margin-left: -15px;
    }

    .dashboard-user-info-card {
      border-radius: 0 0 10px 10px;
    }
  }
}
